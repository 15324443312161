import { SelectionChangedEvent } from "ag-grid-community";
import { TariffTypeEntity } from "domain/entity/TariffType/TariffTypeEntity";
import { INITIAL_TARIFF_TYPE_COL_DEF } from "presentation/constant/TariffType/TariffTypeMaintenanceColumnDefinition";
import { TariffTypeMaintenanceConstant } from "presentation/constant/TariffType/TariffTypeMaintenanceConstant";
import { useTariffTypeMaintenanceVM } from "presentation/hook/TariffType/useTariffTypeMaintenanceVM";
import { useTariffTypeMaintenanceTracked } from "presentation/store/TariffType/TariffTypeMaintenanceProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useMemo, useRef } from "react";


const TariffTypeTablePanel: React.FC = () => {
    const [tariffTypeState] = useTariffTypeMaintenanceTracked();
    const tariffTypeVM = useTariffTypeMaintenanceVM();
    const gridRef: any = useRef(null);

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();

        tariffTypeVM.updateSelectedRows(selectedRows);
    }, [tariffTypeVM])

    const handleAddClick = useCallback(() => {
        tariffTypeVM.onAdd();
    }, [tariffTypeVM])

    const handleRowDoubleClick = useCallback((entity: TariffTypeEntity) => {
        tariffTypeVM.onEdit(entity);
    }, [tariffTypeVM])

    const memoTable = useMemo(() => {
        return (
            <NbisTable
                id='tariff-type-table'
                headerLabel={TariffTypeMaintenanceConstant.TITLE}
                isNewColumnSetting={true}
                columns={INITIAL_TARIFF_TYPE_COL_DEF}
                data={tariffTypeState.tableData ?? []}
                showPaginator={false}
                editable={false}
                showAddIcon={true}
                onAddClick={handleAddClick}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                selectionMode={false}
                isRowHighligted={true}
                handleSelectionChanged={handleSelectionChange}
                onRowDoubleClick={(e: any, entity: TariffTypeEntity) => handleRowDoubleClick(entity)}
                gridHeight="customHeight"
                customHeight="calc(100vh - 75px)"
                ref={gridRef}
            />
        );
    }, [tariffTypeState.tableData, handleAddClick, handleSelectionChange, handleRowDoubleClick])

    return <><TableWrapper>
        {memoTable}
    </TableWrapper>
    </>;
}

export default memo(TariffTypeTablePanel);
